import getSiteTypeData from "../../core/getSiteTypeData";
import MintedLogo, { logoColors } from "./MintedLogo";
import MintedWeddingWebsitesLogo from "./MintedWeddingWebsitesLogo";
import { css } from "@emotion/react";
import { Icon, IconButton, styleUtils, theme } from "@minted/minted-components";
import React, { Fragment } from "react";

const { rem } = styleUtils;

const navigationHeaderContainerStyles = css`
  align-items: center;
  background: ${theme.colors.gray100};
  border-bottom: ${rem(1)} solid ${theme.colors.gray300};
  box-sizing: border-box;
  color: ${theme.colors.gray700};
  cursor: pointer;
  display: flex;
  height: ${rem(56)};
  justify-content: space-between;
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
  width: 100%;
`;

const hiddenElementStyles = css`
  visibility: hidden;
`;

const navigateToMultiSiteDashboard = () => {
  window.location.assign("/dashboard");
};

export default ({ isOpen, siteType, toggleMenu }) => {
  const { isOnlineInvitationSite } = getSiteTypeData({
    siteType,
  });

  return (
    <div css={navigationHeaderContainerStyles} onClick={toggleMenu}>
      <Fragment>
        <div css={isOpen && hiddenElementStyles} data-cy="headerBackButton">
          <IconButton
            iconType={Icon.types["arrow-left"]}
            onClick={navigateToMultiSiteDashboard}
            size={IconButton.sizes.large}
            type={IconButton.types.secondary}
          />
        </div>
        {isOnlineInvitationSite ? (
          <MintedLogo alt="Minted Online Invitation" color={logoColors.black} />
        ) : (
          <MintedWeddingWebsitesLogo
            alt="Minted Wedding Websites"
            color={logoColors.dark}
            height={20}
            width={226}
          />
        )}
        <Icon type={isOpen ? "close" : "menu"} />
      </Fragment>
    </div>
  );
};
