import { css, Global } from "@emotion/react";
import {
  GlobalStyles as MintedComponentsGlobalStyles,
  theme,
} from "@minted/minted-components";
import React from "react";

const globalStyles = (backgroundColor) => css`
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    ${theme.typeStyles.bodyLargeStyles};
    background: ${backgroundColor};
  }
`;

const rootStyles = css`
  height: 100%;
  min-height: 100%;
  position: absolute;
  width: 100%;
`;

export default ({
  backgroundColor = `${theme.colors.gray200}`,
  children,
  overflowY = "",
}) => (
  <div
    css={css`
      ${rootStyles}
      ${overflowY && `overflow-y: ${overflowY};`}
    `}
  >
    <MintedComponentsGlobalStyles />
    <Global styles={globalStyles(backgroundColor)} />

    {children}
  </div>
);
