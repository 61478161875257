import useOpen from "../../core/hooks/useOpen";
import HolidayMobileNavigationHeader from "./HolidayMobileNavigationHeader";
import NavigationContext from "./NavigationContext";
import ViewWebsite from "./ViewWebsite";
import { css, Global } from "@emotion/react";
import { Button, Icon, styleUtils, theme } from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { useContext } from "react";

const { rem } = styleUtils;

const navigationLinkStyles = css`
  ${theme.typeStyles.bodySmallStyles};
  ${theme.typeStyles.bodyBoldStyles};
  align-items: center;
  border-left: ${rem(3)} solid transparent;
  color: ${theme.colors.gray700};
  cursor: pointer;
  display: flex;
  height: ${rem(48)};
  padding-left: ${theme.spacing.x6};

  :hover {
    background: rgba(230, 230, 230, 0.25);
  }
`;

const mintedUrlLinkStyles = css`
  ${theme.typeStyles.bodyExtraSmallStyles}
  align-items: center;
  border-left: ${rem(3)} solid transparent;
  color: ${theme.colors.gray700};
  cursor: pointer;
  display: flex;
  height: ${rem(32)};
  padding-left: ${theme.spacing.x6};
  text-decoration: none;
  :hover {
    background: rgba(102, 102, 102, 0.25);
  }
`;

const openNavContainerStyles = css`
  background: ${theme.colors.gray100};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9;
`;

const navigationLinkContainerStyles = css`
  border-bottom: ${rem(1)} solid ${theme.colors.gray300};
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationLogoutContainerStyles = css`
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationContainerOverflowScrollStyles = css`
  overflow-y: scroll;
`;

const propTypes = {
  isMultiSiteDashboard: PropTypes.bool,
};

const SimplifiedMobileNavigation = ({ isMultiSiteDashboard }) => {
  const { mintedUrls } = useContext(NavigationContext);

  const { isOpen, toggle } = useOpen();

  return (
    <div css={isOpen && openNavContainerStyles}>
      {isOpen && (
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
      )}

      <HolidayMobileNavigationHeader
        isMultiSiteDashboard={isMultiSiteDashboard}
        isOpen={isOpen}
        toggleMenu={toggle}
      />

      {isOpen && (
        <div css={navigationContainerOverflowScrollStyles}>
          {!isMultiSiteDashboard && (
            <div css={navigationLinkContainerStyles}>
              <ViewWebsite>
                {({ navigateToWeddingWebsite }) => (
                  <div css={navigationLinkStyles} data-cy="viewYourWebsiteLink">
                    <Button
                      iconAlignment={Button.iconAlignments.left}
                      iconType={Icon.types["open-in-new"]}
                      onClick={navigateToWeddingWebsite}
                      text="View Your Website"
                      type={Button.types.tertiary}
                    />
                  </div>
                )}
              </ViewWebsite>
            </div>
          )}

          <div css={navigationLinkContainerStyles}>
            <a
              css={mintedUrlLinkStyles}
              data-cy="myAccountLink"
              href={mintedUrls["my-account"]}
            >
              My Account
            </a>
            <a css={mintedUrlLinkStyles} href={mintedUrls.help}>
              Website Help
            </a>
            <a css={mintedUrlLinkStyles} href={mintedUrls.terms}>
              Terms & Conditions
            </a>
            <a css={mintedUrlLinkStyles} href={mintedUrls.privacy}>
              Privacy Policy
            </a>
          </div>
          <div css={navigationLogoutContainerStyles}>
            <a
              css={mintedUrlLinkStyles}
              data-cy="logoutLink"
              href={mintedUrls.logout}
            >
              Logout
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

SimplifiedMobileNavigation.propTypes = propTypes;

export default SimplifiedMobileNavigation;
