import MintedWebsitesLogo from "./MintedWebsitesLogo";
import NavigationContext from "./NavigationContext";
import ViewWebsite from "./ViewWebsite";
import { css } from "@emotion/react";
import {
  Button,
  Icon,
  IconButton,
  Popover,
  styleUtils,
  theme,
} from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";

const { rem, transition } = styleUtils;

const propTypes = {
  forwardRef: PropTypes.object,
  isCollapsed: PropTypes.bool,
  isMultiSiteDashboard: PropTypes.bool,
};

const navigationHeadingStyles = css`
  ${transition("all")};
  align-items: center;
  background: ${theme.colors.gray100};
  border-bottom: ${rem(3)} solid ${theme.colors.gray300};
  display: flex;
  height: ${rem(64)};
  justify-content: space-between;
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
`;

const hyperlinkStyles = css`
  ${theme.typeStyles.bodySmallStyles}
  cursor: pointer;
  display: block;
  padding: ${theme.spacing.x3} ${theme.spacing.x6};
  text-decoration: none;
  width: ${rem(200)};
`;

const websiteLinkStyles = css`
  ${theme.typeStyles.bodyBoldStyles};
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: ${theme.spacing.x6};
`;

const popoverPaddingStyles = css`
  padding-bottom: ${theme.spacing.x4};
  padding-top: ${theme.spacing.x4};
`;

const iconButtonStyles = css`
  margin-right: ${theme.spacing.x4};
`;

const navigateToMultiSiteDashboard = () => {
  window.location.assign("/dashboard");
};

const SimplifiedNavigation = ({ isMultiSiteDashboard }) => {
  const { mintedUrls } = useContext(NavigationContext);

  return (
    <div css={navigationHeadingStyles}>
      {!isMultiSiteDashboard && (
        <div css={iconButtonStyles} data-cy="headerBackButton">
          <IconButton
            iconType={Icon.types["arrow-left"]}
            onClick={navigateToMultiSiteDashboard}
            size={IconButton.sizes.large}
            type={IconButton.types.secondary}
          />
        </div>
      )}
      <MintedWebsitesLogo />

      {!isMultiSiteDashboard && (
        <ViewWebsite>
          {({ navigateToWeddingWebsite }) => (
            <div css={websiteLinkStyles} data-cy="viewYourWebsiteLink">
              <Button
                iconAlignment={Button.iconAlignments.left}
                iconType={Icon.types["open-in-new"]}
                onClick={navigateToWeddingWebsite}
                text="View Your Website"
                type={Button.types.tertiary}
              />
            </div>
          )}
        </ViewWebsite>
      )}

      {isMultiSiteDashboard && (
        <Popover.PopoverManager>
          {({ contentRef, isOpen, targetRef, togglePopover }) => (
            <Fragment>
              <span data-cy="accountMenu" ref={targetRef}>
                <IconButton
                  iconType={Icon.types.account}
                  onClick={togglePopover}
                  size={IconButton.sizes.large}
                  type={IconButton.types.secondary}
                />
              </span>

              <Popover open={isOpen} ref={contentRef}>
                <Popover.PopoverContent>
                  <div css={popoverPaddingStyles}>
                    <a
                      css={hyperlinkStyles}
                      data-cy="myAccountLink"
                      href={mintedUrls["my-account"]}
                    >
                      My Account
                    </a>
                    <a
                      css={hyperlinkStyles}
                      data-cy="logoutLink"
                      href={mintedUrls.logout}
                    >
                      Logout
                    </a>
                  </div>
                </Popover.PopoverContent>
              </Popover>
            </Fragment>
          )}
        </Popover.PopoverManager>
      )}
    </div>
  );
};

SimplifiedNavigation.propTypes = propTypes;

export default SimplifiedNavigation;
