import { DIGITAL_WEDDING_WEBSITE_PK } from "../../website/constants";
import { getDesign } from "../../website/utils";
import { css } from "@emotion/react";
import { LoadingSpinner } from "@minted/minted-components";
import PropTypes from "prop-types";
import { React, useState, useEffect } from "react";

const couplePhotoDefault = require("../../website/images/website/photo-couple.png");

const fitToParent = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const image = css`
  width: 100%;
  height: auto;
`;

const grayscaleStyles = css`
  ${image}
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
`;

const ImageWrapper = ({ children, siteUrl }) => {
  if (siteUrl) {
    return (
      <a css={fitToParent} href={siteUrl}>
        {children}
      </a>
    );
  }

  return <div css={fitToParent}>{children}</div>;
};

const SiteCardImage = ({ defaultImage, sectionType, site, siteUrl }) => {
  const [imageUrl, setImageUrl] = useState(
    defaultImage || site.savedDesignImage?.preview || site.previewUrl || "",
  );
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  useEffect(() => {
    if (site.isOnlineInvitation) {
      setImageUrl(defaultImage);
    }
  }, [defaultImage, site.isOnlineInvitation]);

  useEffect(() => {
    if (site.isWedding && !Boolean(site.previewUrl)) {
      setIsLoadingImage(true);
      getDesign(site.mintedProductSku)
        .then((results) => {
          const colorways = results.products.find(
            (product) => product.kind.code === DIGITAL_WEDDING_WEBSITE_PK,
          ).colorways;
          const colorway = colorways.find(
            (colorway) =>
              colorway.externalId === site.mintedProductColorChoiceId,
          );

          setImageUrl(colorway.images.pz);
        })
        .catch(() => {
          setImageUrl(couplePhotoDefault);
        })
        .finally(() => {
          setIsLoadingImage(false);
        });
    }
  }, [
    site.isWedding,
    site.mintedProductColorChoiceId,
    site.mintedProductSku,
    site.previewUrl,
  ]);

  return (
    <ImageWrapper siteUrl={siteUrl}>
      <LoadingSpinner
        alignment="center"
        loading={isLoadingImage}
        variation="dark"
      />
      <img
        css={
          ["archived", "deleted"].includes(sectionType)
            ? grayscaleStyles
            : image
        }
        data-cy="sitePreview"
        src={imageUrl}
      />
    </ImageWrapper>
  );
};

ImageWrapper.propTypes = {
  children: PropTypes.array.isRequired,
  siteUrl: PropTypes.string,
};

SiteCardImage.propTypes = {
  defaultImage: PropTypes.string,
  sectionType: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  siteUrl: PropTypes.string,
};

export default SiteCardImage;
