import { useCallback, useEffect, useState } from "react";

export default ({ propName, ref }) => {
  const [value, saveValue] = useState(0);

  const onValueChanged = useCallback(() => {
    saveValue(ref.current[propName]);
    // eslint-disable-next-line
  }, [propName, ref.current]);

  useEffect(() => {
    onValueChanged();
    window.addEventListener("resize", onValueChanged);

    return () => {
      window.removeEventListener("resize", onValueChanged);
    };
  }, [onValueChanged]);

  return value;
};
