import { scrollToTop } from "../../core/windowUtils";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "redux-router5";

export default (routePath) => {
  const dispatch = useDispatch();

  const navigate = useCallback(() => {
    bindActionCreators(actions.navigateTo, dispatch)(routePath);
    scrollToTop();
  }, [dispatch, routePath]);

  return navigate;
};
