import MintedWebsitesLogo, { logoColors } from "./MintedWebsitesLogo";
import { css } from "@emotion/react";
import { Icon, IconButton, styleUtils, theme } from "@minted/minted-components";
import React from "react";

const { rem } = styleUtils;

const navigationHeaderContainerStyles = css`
  align-items: center;
  background: ${theme.colors.gray100};
  border-bottom: ${rem(1)} solid ${theme.colors.gray300};
  box-sizing: border-box;
  color: ${theme.colors.gray700};
  cursor: pointer;
  display: flex;
  height: ${rem(56)};
  justify-content: space-between;
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
  width: 100%;
`;

const hiddenArrowIconStyles = css`
  visibility: hidden;
`;

const navigateToMultiSiteDashboard = () => {
  window.location.assign("/dashboard");
};

export default ({ isMultiSiteDashboard, isOpen, toggleMenu }) => (
  <div css={navigationHeaderContainerStyles}>
    {isMultiSiteDashboard ? (
      <div css={hiddenArrowIconStyles} data-cy="headerBackButton">
        <IconButton
          disabled
          iconType={Icon.types["arrow-left"]}
          onClick={navigateToMultiSiteDashboard}
          size={IconButton.sizes.large}
          type={IconButton.types.secondary}
        />
      </div>
    ) : (
      <div css={isOpen && hiddenArrowIconStyles} data-cy="headerBackButton">
        <IconButton
          iconType={Icon.types["arrow-left"]}
          onClick={navigateToMultiSiteDashboard}
          size={IconButton.sizes.large}
          type={IconButton.types.secondary}
        />
      </div>
    )}

    <MintedWebsitesLogo alt="Minted Websites" color={logoColors.dark} />
    <IconButton
      iconType={isOpen ? Icon.types.close : Icon.types.menu}
      onClick={toggleMenu}
      size={IconButton.sizes.large}
      type={IconButton.types.secondary}
    />
  </div>
);
