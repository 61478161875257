import config from "config";

const weddingHelpLink =
  "https://help2.minted.com/s/global-search/wedding%20website";
const genericHelpLink =
  "https://help2.minted.com/s/topic/0TO4y000000wlAyGAI/minted-websites";

const mintedUrlPaths = [
  "backbone",
  "cart",
  "contact-us",
  "feedback",
  "logout",
  "my-saved-designs",
  "privacy",
  "terms",
];

export default mintedUrlPaths.reduce(
  (mintedUrls, mintedUrlPath) => ({
    ...mintedUrls,
    [mintedUrlPath]: config
      .get("mintedBaseUrl")
      .concat("/")
      .concat(mintedUrlPath),
  }),
  {
    help: genericHelpLink,
    helpWedding: weddingHelpLink,
  },
);
