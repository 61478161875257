import { css } from "@emotion/react";
import { Alert, Button, Modal, theme } from "@minted/minted-components";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  alertPrimaryText: PropTypes.string,
  alertSecondaryText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  children: PropTypes.node,
  isDeleteModal: PropTypes.bool,
  justAlert: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showCancel: PropTypes.bool,
  submitButtonText: PropTypes.string,
};

const modalBodyTextStyles = css`
  ${theme.typeStyles.bodySmallStyles};
`;

const buttonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ConfirmationModal = ({
  alertPrimaryText = "Are you sure?",
  cancelButtonText = "Cancel",
  children,
  isDeleteModal = false,
  onCloseClick,
  onSubmit,
  open = false,
  showCancel = true,
  submitButtonText = "Yes, Delete",
}) => (
  <Modal
    displayCloseButton={false}
    onCloseClick={onCloseClick}
    open={open}
    zIndex={999999}
  >
    <div data-cy="confirmationModal">
      <Alert
        primaryText={alertPrimaryText}
        secondaryText=""
        show
        type={Alert.types.informative}
      />
      <Modal.Body>
        <div css={modalBodyTextStyles}>{children}</div>
      </Modal.Body>
      <Modal.Footer>
        <div css={buttonContainerStyles}>
          {showCancel && (
            <div data-cy="confirmationCancelButton">
              <Button
                onClick={onCloseClick}
                text={cancelButtonText}
                type={Button.types.tertiary}
              />
            </div>
          )}
          <div data-cy="confirmationSubmitButton">
            <Button
              onClick={onSubmit}
              submit
              text={submitButtonText}
              type={
                isDeleteModal ? Button.types.destructive : Button.types.primary
              }
            />
          </div>
        </div>
      </Modal.Footer>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = propTypes;

export default ConfirmationModal;
