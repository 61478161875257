import { css } from "@emotion/react";
import {
  styleUtils,
  theme,
  useMediaQueryState,
} from "@minted/minted-components";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
};

const OnboardingArtistAttribution = ({ location, name, photo }) => {
  const smallMediaQueryState = useMediaQueryState({
    mediaQuerySize: "small",
  });

  return smallMediaQueryState === "BELOW" ? (
    <div
      css={css`
        ${theme.typeStyles.bodyExtraSmallStyles}
        color: ${theme.colors.gray500};
        position: absolute;
        top: ${theme.spacing.x16};
      `}
    >
      Design by {name}
    </div>
  ) : (
    <div
      css={css`
        align-items: center;
        padding-bottom: ${theme.spacing.x6};
        bottom: 0;
        background-color: ${theme.colors.gray100};
        display: flex;
        position: fixed;
        width: 100%;
        justify-content: center;
      `}
    >
      <div
        css={css`
          ${theme.typeStyles.bodyLargeStyles}
          ${theme.typeStyles.bodyBoldStyles}
        `}
      >
        Artwork by
      </div>
      <div
        css={css`
          border: ${styleUtils.rem(1)} solid ${theme.colors.gray100};
          border-radius: ${styleUtils.rem(50)};
          height: ${styleUtils.rem(36)};
          margin-left: ${theme.spacing.x3};
          margin-right: ${theme.spacing.x3};
          overflow: hidden;
          width: ${styleUtils.rem(36)};
        `}
      >
        <img
          alt={name}
          css={css`
            width: 100%;
            height: 100%;
          `}
          src={photo}
        />
      </div>
      <div>
        <div
          css={css`
            ${theme.typeStyles.bodyExtraSmallStyles}
            ${theme.typeStyles.bodyBoldStyles}
          `}
        >
          {name}
        </div>
        <div
          css={css`
            ${theme.typeStyles.bodyExtraSmallStyles}
            color: ${theme.colors.gray500};
          `}
        >
          {location}
        </div>
      </div>
    </div>
  );
};

OnboardingArtistAttribution.propTypes = propTypes;

export default OnboardingArtistAttribution;
