import RouterContext from "./RouterContext";
import useNavigation from "./useNavigation";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  children: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  routeName: PropTypes.string.isRequired,
  type: PropTypes.string,
};

const NavigationLink = ({ children, routeName }) => {
  const onNavigate = useNavigation(routeName);

  return (
    <RouterContext.Consumer>
      {({ router }) =>
        children({
          isActive: router.isActive(routeName),
          onNavigate,
        })
      }
    </RouterContext.Consumer>
  );
};

NavigationLink.propTypes = propTypes;

export default NavigationLink;
